import React from "react";
import { Input, Form, Button, Card, Col, Row } from "antd";
import StateSelect from "./state-select";
import ShirtColorSelect from "./shirt-color-select";
import COLORS from "./lib/colors";

export default class OrderImage extends React.Component {
  state = {
    lat: null,
    lng: null,
    state: "GA",
    city: "Macon",
    color: COLORS.white,
    svgUrl: null,
    pngUrl: null
  };

  updateLatLng = e => {
    const val = e.target.value;
    const [lat, lng] = val.split(", ");
    this.setState({ lat, lng });
  };

  updateState = value => {
    this.setState({ state: value });
  };

  updateCity = e => {
    console.log(e.target.value);
    this.setState({ city: e.target.value });
  };

  updateColor = color => {
    this.setState({ color: COLORS[color] });
  };

  handleSubmit = () => {
    const { color, city, state, lat, lng } = this.state;
    if (color && city && state && lat && lng) {
      const base = "https://loprints-api-staging.herokuapp.com/api";
      const query = `?state=${state}&city=${city}&lat=${lat}&long=${lng}&shapeColor=${
        color.graphicColor
      }&pinColor=${color.pinColor}`;
      this.setState({
        svgUrl: `${base}/state${query}`,
        pngUrl: `${base}/convert${query}`
      });
    }
  };

  render() {
    const { svgUrl, pngUrl, color } = this.state;
    return (
      <div className="order-image-container">
        <h2>Configure your graphic</h2>
        <p>
          <i>
            Try <code>32.8407, -83.6324</code> in Georgia!
          </i>
        </p>
        <Form layout="inline">
          <Form.Item>
            <ShirtColorSelect onChange={this.updateColor} />
          </Form.Item>
          <Form.Item>
            <Input
              size="large"
              placeholder="Macon"
              style={{ width: 200 }}
              onChange={this.updateCity}
            />
          </Form.Item>
          <Form.Item>
            <StateSelect onChange={this.updateState} />
          </Form.Item>
          <Form.Item>
            <Input
              addonBefore="Lat, Lng"
              size="large"
              placeholder="36.0000, -80.0000"
              style={{ width: 300 }}
              onChange={this.updateLatLng}
            />
          </Form.Item>
          <Form.Item>
            <Button size="large" type="primary" onClick={this.handleSubmit}>
              Generate Image
            </Button>
          </Form.Item>
        </Form>

        <div style={{ marginTop: "50px" }}>
          <h2>Results</h2>
          {pngUrl ? (
            <Row gutter={16}>
              <Col span={8}>
                <Card
                  title="SVG Image"
                  extra={
                    <a
                      href={svgUrl}
                      download
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Download
                    </a>
                  }
                >
                  <div className="order-preview">
                    <img
                      className="order-preview"
                      src={svgUrl}
                      alt="svg preview"
                    />
                  </div>
                </Card>
              </Col>
              <Col span={8}>
                <Card title="PNG Image">
                  <div className="order-preview">
                    <img
                      className="order-preview"
                      src={pngUrl}
                      alt="png preview"
                    />
                  </div>
                </Card>
              </Col>
              <Col span={8}>
                <Card title="Shirt Preview">
                  <div className="preview">
                    <img
                      className="preview__shirt"
                      src={color.shirt}
                      alt={`${color.name} Shirt`}
                    />
                    <img
                      className="preview__graphic"
                      src={svgUrl}
                      alt="svg shirt preview"
                    />
                  </div>
                </Card>
              </Col>
            </Row>
          ) : (
            <p>
              <i>Results will show up here.</i>
            </p>
          )}
        </div>
      </div>
    );
  }
}
