import whiteShirt from "../shirts/white.jpg";
import blackShirt from "../shirts/black.jpg";
import heathermintShirt from "../shirts/heathermint.jpg";
import heatherraspberryShirt from "../shirts/heatherraspberry.jpg";
import trueroyalShirt from "../shirts/trueroyal.jpg";
import goldShirt from "../shirts/gold.jpg";

const PIN_COLORS = {
  red: "B81F26",
  white: "EFEFEF"
};

export default {
  white: {
    id: "white",
    name: "White",
    color: "EEEEEE",
    graphicColor: "C6C6C6",
    pinColor: PIN_COLORS.red,
    shirt: whiteShirt
  },
  black: {
    id: "black",
    name: "Black",
    color: "282828",
    graphicColor: "454545",
    pinColor: PIN_COLORS.red,
    shirt: blackShirt
  },
  heathermint: {
    id: "heathermint",
    name: "Heather Mint",
    color: "A7D5C8",
    graphicColor: "FFFFFF",
    pinColor: PIN_COLORS.red,
    shirt: heathermintShirt
  },
  heatherraspberry: {
    id: "heatherraspberry",
    name: "Heather Raspberry",
    color: "C75774",
    graphicColor: "770836",
    pinColor: PIN_COLORS.white,
    shirt: heatherraspberryShirt
  },
  trueroyal: {
    id: "trueroyal",
    name: "True Royal",
    color: "07458A",
    graphicColor: "FFFFFF",
    pinColor: PIN_COLORS.red,
    shirt: trueroyalShirt
  },
  gold: {
    id: "gold",
    name: "Gold",
    color: "F19C12",
    graphicColor: "FFFFFF",
    pinColor: PIN_COLORS.red,
    shirt: goldShirt
  }
};
