import React from "react";
import { Select } from "antd";
import STATES from "./lib/states";

const Option = Select.Option;

export default ({ onChange }) => {
  return (
    <Select
      showSearch
      size="large"
      style={{ width: 160 }}
      placeholder="State"
      optionFilterProp="children"
      onChange={onChange}
      filterOption={(input, option) =>
        option.props.children[option.props.children.length - 1]
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      }
    >
      {Object.keys(STATES).map(key => (
        <Option value={key} key={key}>
          ({key}) {STATES[key]}
        </Option>
      ))}
    </Select>
  );
};
