import React, { Component } from "react";
import "./App.css";
import logo from "./logo.svg";
import { Layout, Menu, Icon } from "antd";
import OrderImage from "./order-image";
import GoogleLogin from "react-google-login";

const { Content, Footer, Sider } = Layout;

const responseGoogle = response => {
  console.log(response);
};

class App extends Component {
  render() {
    return (
      <Layout style={{ minHeight: "100vh" }}>
        <Sider>
          <img src={logo} className="logo" alt="LoPrints" />
          <Menu theme="dark" defaultSelectedKeys={["1"]} mode="inline">
            <Menu.Item>
              <GoogleLogin
                clientId="133458954004-vudsm9rfb0musgp07i102jjf18bpk4q4.apps.googleusercontent.com"
                buttonText="Login"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                render={renderProps => (
                  <span onClick={renderProps.onClick}>Login</span>
                )}
              />
            </Menu.Item>
            <Menu.Item key="1">
              <Icon type="file" />
              <span>Make Order Image</span>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout>
          <Content style={{ margin: "0 16px" }}>
            <div
              style={{
                padding: 24,
                background: "#fff",
                minHeight: 360,
                margin: "16px 0"
              }}
            >
              <OrderImage />
            </div>
          </Content>
          <Footer style={{ textAlign: "center" }}>
            LoPrints ©2018 Created by LoPrints
          </Footer>
        </Layout>
      </Layout>
    );
  }
}

export default App;
