import React from "react";
import { Select } from "antd";
import COLORS from "./lib/colors";

const Option = Select.Option;

export default ({ onChange }) => {
  return (
    <Select
      style={{ width: 200 }}
      placeholder="Shirt color"
      onChange={onChange}
      size="large"
    >
      {Object.keys(COLORS).map(key => (
        <Option key={key} value={key}>
          {COLORS[key].name}
        </Option>
      ))}
    </Select>
  );
};
